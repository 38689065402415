$hue: 40;
$link-hue: 100;
$visited-hue: 260;

// $bg-color: hsla($hue, 25%, 100%, 1);
$t-color: hsla($hue, 25%, 20%, 1);
$title-color: hsla($hue, 25%, 10%, 1);
$a-color: hsla($hue, 100%, 51%, 1);
$a-hover-color: hsla($link-hue, 67%, 47%, 1);
$a-hover-bg-color: hsla($link-hue, 67%, 47%, 0.1);
$a-visited-color: hsla($visited-hue, 100%, 51%, 1);
$a-plain-bg-color: hsla($hue, 25%, 95%, 1);
$code-color: hsla($hue, 50%, 93%, 1);
$meta-color: hsla($hue, 25%, 45%, 1);
$nav-bg-color: hsla($hue, 25%, 95%, 1);
$discuss-color: hsla(203, 88%, 94%, 1);
$bg-color: $nav-bg-color;

$sans-fonts: -apple-system, blinkmacsystemfont, "Helvetica Neue", "Helvetica", "Segoe UI", roboto, oxygen-sans, ubuntu, cantarell,
    "Helvetica Neue", sans-serif;
$mono-fonts: "SFMono-Regular", consolas, "Liberation Mono", menlo, courier, monospace;
$curly-quote-fonts: georgia, times, "Times New Roman", serif;

$big-width: 60rem;

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    background-color: $bg-color;
    color: $t-color;
    font-family: $sans-fonts;
}

h1,
h2,
h3,
h4,
h5,
h6,
hgroup,
ul,
ol,
dd,
p,
figure,
pre,
table,
fieldset,
hr {
    margin-top: 0;
    margin-bottom: 1.5rem;
}

h1 {
    font-size: 3.375rem;
    line-height: 1.3em;
    color: $title-color;
}

h2 {
    font-size: 1.5rem;
    line-height: 1.3em;
    color: $title-color;

    @media screen and (min-width: $big-width) {
        font-size: 2.25rem;
        line-height: 1.3em;
    }
}

h3 {
    font-size: 1.5rem;
    line-height: 1.3em;
    color: $title-color;
    font-weight: 700;
}

p {
    font-size: 1rem;
    line-height: 1.5em;
}

p code {
    font-size: 0.9rem;
    line-height: 1em;

    @media screen and (min-width: $big-width) {
        font-size: 1rem;
    }
}

dl {
    background-color: lighten($t-color, 78%);
    border-radius: 0.5rem;
    padding: 3rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;

    @media screen and (min-width: $big-width) {
        display: flex;
        flex-flow: row wrap;
        padding-bottom: 0;
    }
}

dt {
    flex-basis: 30%;
    padding: 0.75rem;
    padding-bottom: 0;
    padding-left: 0;
    font-weight: 700;

    code {
        font-weight: 700;
    }
}

dd {
    flex-basis: 60%;
    flex-grow: 1;
    margin: 0;
    padding: 0.75rem;
    padding-bottom: 0;
    padding-right: 0;
}

p dfn {
    border-bottom: 2px dotted darken($nav-bg-color, 10%);
    font-style: normal;
    font-weight: 600;
}

figure {
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    @media screen and (min-width: $big-width) {
        margin-left: 0;
        margin-right: 0;

        &.full {
            margin-left: calc((46% + 3rem) * -1);
            margin-top: 3rem;
            margin-bottom: 3rem;
            margin-right: -1.5rem;
            border-radius: 0.5rem;
        }
    }

    & .figure-link,
    & .figure-link:visited {
        float: right;
        color: $t-color;
        margin-right: -0.5rem;

        span {
            margin-right: 0;
            background-color: darken($code-color, 10%);
            color: lighten($t-color, 10%);
        }

        &:hover,
        &:focus {
            background-color: transparent;
            color: $title-color;

            span {
                background-color: $a-color;
                color: $bg-color;
            }
        }
    }
}

figcaption {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 100%;

    & > .figure-opener {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: content;
    }

    & > p {
        flex-grow: 1;
        flex-basis: auto;
        margin: 0;
    }

    & > .figure-link {
        flex: 0 0 content;
    }
}

.figure--code {

    pre {
        @media screen and (max-width: $big-width) {
            padding: 1.5rem 3rem;
            width: calc(100% + 1.5rem);
            max-width: calc(100% + 1.5rem);
        }

        max-width: 100%;
        overflow-x: scroll;

        code {
            padding-right: 1.5rem;
        }
    }

    &.doesnt-compile {
        border: 2px solid hsla(10, 90%, 60%, 1);
        position: relative;

        @media screen and (min-width: $big-width) {
            border-radius: 0.65rem;
        }
    }

    &.doesnt-compile::after {
        content: "Doesn't Compile";
        position: absolute;
        top: 0;
        right: 0;
        font-size: 0.85rem;
        line-height: 1.3em;
        text-transform: lowercase;
        font-variant: small-caps;
        padding: 0.25rem 0.75rem 0.45rem;
        background-color: hsla(10, 90%, 60%, 1);
        color: hsla(0, 0%, 100%, 1);
        border-bottom-left-radius: 0.65rem;
        border-top-right-radius: 0.5rem;
    }

    &.doesnt-compile pre,
    &.doesnt-compile code {
        background-color: hsla(10, 90%, 95%, 1);
    }

    &.doesnt-compile figcaption {
        background-color: hsla(10, 90%, 92%, 1);
    }

    &.doesnt-compile figcaption span {
        background-color: hsla(10, 90%, 80%, 1);
        color: hsla(10, 90%, 20%, 1);
    }
}

figure img {
    max-width: 100%;
    display: block;

    @media screen and (min-width: $big-width) {
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
    }
}

aside {
    @media screen and (min-width: $big-width) {
        margin-left: calc((42.85714286% + 3rem) * -1);
        width: 42.85714286%;
        float: left;
        padding-right: 1.5rem;
    }

    p {
        font-size: 0.8rem;
    }

    p a,
    p a:visited {
        background-color: darken($bg-color, 5%);
        color: lighten($t-color, 10%);
    }

    code {
        font-size-adjust: 0.45;
    }

    color: lighten($t-color, 10%);

    h4 {
        border-top: 2px solid darken($bg-color, 30%);
        padding-top: 0.75rem;
        margin-top: 0.5rem;
        text-transform: uppercase;
        margin-bottom: 0.75rem;
        font-size: 0.75rem;
        line-height: 1.5em;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        code {
            text-transform: none;
            font-size-adjust: 0.6;
        }
    }

    & .aside-num {
        flex-shrink: 0;
        flex-grow: 0;
        flex-basis: content;
        margin-right: 0.25em;
        opacity: 0.8;
        font-weight: 500;
    }

    & .aside-title {
        flex-basis: content;
        flex-grow: 1;
    }

    & .aside-link,
    & .aside-link:visited {
        font-weight: 700;
        margin-top: -2px;
        text-transform: uppercase;
        padding: 0.15rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        line-height: 1.5em;
        vertical-align: 0.125rem;
        background-color: lighten($t-color, 75%);
        float: right;
        color: lighten($t-color, 30%);
        text-decoration: none;

        span {
            margin-right: 0;
        }

        &:hover,
        &:focus {
            background-color: $a-color;
            color: $title-color;

            span {
                background-color: $a-color;
                color: $bg-color;
            }
        }
    }
}

figcaption {
    background-color: darken($code-color, 2%);
    padding: 0.75rem 2rem 0.75rem 1rem;

    @media screen and (min-width: $big-width) {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }

    .figure--table & {
        border-radius: 0;

        @media screen and (min-width: $big-width) {
            border-top-left-radius: 0.5rem;
            border-top-right-radius: 0.5rem;
        }
    }

    span {
        font-weight: 700;
        margin-right: 0.5rem;
        text-transform: uppercase;
        padding: 0.15rem 0.5rem;
        border-radius: 0.25rem;
        display: inline-block;
        font-size: 0.75rem;
        line-height: 1.5em;
        vertical-align: 0.125rem;
        background-color: darken($code-color, 10%);
    }
}

a {
    color: $a-color;
}

pre {
    padding: 1.5rem;
    line-height: 1.5em;
    overflow-x: scroll;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    figure & {
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    @media screen and (min-width: $big-width) {
        border-radius: 0.5rem;
        margin-left: 0;
        margin-right: 0;
    }
}

pre,
code {
    background-color: $code-color;
    font-family: $mono-fonts;
    font-weight: 500;
    font-size-adjust: 0.5;
}

pre {
    font-size: 0.75rem;
    line-height: 1.5em;

    @media screen and (min-width: $big-width) {
        font-size: 90%;
        line-height: 1.5em;
    }
}

a:hover,
a:focus,
a:visited:hover,
a:visited:focus {
    color: $a-hover-color;
    background-color: $a-hover-bg-color;
}

a:visited {
    color: $a-visited-color;
}

a.plain,
a.plain:visited {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
}

a.plain:hover,
a.plain:focus,
a.plain:visited:hover,
a.plain:visited:focus {
    color: $a-hover-color;
    background-color: $a-hover-bg-color;
}

a.meta {
    background-color: transparent;
    color: inherit;
}

a.meta:hover,
a.meta:focus,
a.meta:visited:hover,
a.meta:visited:focus {
    background-color: $a-plain-bg-color;
    color: $a-hover-color;
}

a code {
    background-color: $code-color;
    color: $t-color;
}

hr {
    margin: 1.5rem 0;
    padding: 0;
    display: block;
    height: 0;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid $code-color;
}

ul li,
ol li {
    line-height: 1.5em;
}

dt,
dd {
    line-height: 1.5em;
}

table {
    border-collapse: collapse;

    .figure--table & {
        width: 100%;
    }
}

th,
td {
    padding: 0.5rem 0.75rem;
    font-size: 0.75rem;
    line-height: 1.5em;
    border: 1px solid $a-plain-bg-color;

    tbody & {
        background-color: #fff;
    }

    @media screen and (min-width: $big-width) {
        padding: 0.83rem 1.5rem;
        font-size: 1rem;
        line-height: 1.5em;
    }
}

th {
    background-color: darken($code-color, 5%);
    border: 0;
    font-weight: 500;
}

blockquote {
    border-left: 0.5rem solid $code-color;
    background-color: lighten($code-color, 2.5%);
    padding: 1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    position: relative;

    @media screen and (min-width: $big-width) {
        margin-left: 0;
        margin-right: 0;
    }

    &::before {
        content: "“";
        position: absolute;
        top: 0;
        left: -2rem;
        font-size: 7.59375rem;
        line-height: 1em;
        letter-spacing: -0.5em;
        width: 3rem;
        height: 3rem;
        background-color: lighten($code-color, 2.5%);
        border-radius: 50%;
        padding: 0.25rem;
        padding-left: 0.375rem;
        font-family: $curly-quote-fonts;
        color: darken($code-color, 10%);

        @media screen and (min-width: $big-width) {
            top: 1.5rem;
            left: -1.75rem;
            color: darken($code-color, 70%);
            font-size: 5.0625rem;
            line-height: 1em;
        }
    }

    > * {
        margin-bottom: 0;
    }

    > * + * {
        margin-top: 1.5rem;
    }
}

.button,
.button:visited {
    display: inline-block;
    padding: 0.75rem 1.5rem;
    border-radius: 5rem;
    background-color: $a-color;
    color: $bg-color;
    text-decoration: none;
    text-align: center;
    font-weight: 700;

    &.button--block {
        display: block;
    }

    & svg {
        fill: $bg-color;
        margin-right: 0.5rem;
        vertical-align: -0.6rem;
    }

    span {
        display: block;
        font-size: 0.75rem;
        line-height: 1.5em;
        font-weight: 500;
    }
}

.site {
    padding: 3rem 1.5rem;

    @media screen and (max-width: $big-width) {

        .site-body {
            display: flex;
            flex-direction: column;
        }

        .site-header {
            padding-top: 1.5rem;
            margin-top: 1.5rem;
            order: 2;
            margin-bottom: -1.5rem;
        }

        .site-content {
            order: 1;
        }
    }

    @media screen and (min-width: $big-width) {
        max-width: 64rem;
        padding: 3rem 0 6rem 0;
        margin: 3rem auto 0;

        .site-body {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
        }

        .site-header {
            display: block;
            flex: 0 0 30%;
            margin-right: 3rem;
        }

        .site-content {
            flex: 1;
        }
    }
}

.site-header,
.post-preview {
    padding-bottom: 1.5rem;
    margin-bottom: 1.55rem;
}

.post-preview {

    & + & {
        margin-top: 3rem;
        padding-top: 3rem;
        border-top: 1px solid $code-color;
    }
}

.site-title {
    font-size: 2.25rem;
    line-height: 1.5em;
    margin-bottom: 0.15rem;
    text-align: left;

    .site > & {
        margin-left: 30%;
    }

    br {
        display: none;
    }

    a {

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
            opacity: 1;
            color: $title-color;
        }
    }

    @media screen and (min-width: $big-width) {
        font-size: 2.25rem;
        line-height: 1.3em;
    }

    > span {
        font-size: 0.85rem;
        line-height: 1.5em;
        padding: 0.25rem 0.75rem;
        border-radius: 0.5rem;
        background-color: darken($bg-color, 10%);
        font-weight: 500;
        display: inline-block;
        vertical-align: 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
    }

    &--inline {
        text-align: inherit;
        font-size: 2.25rem;
        line-height: 1.5em;

        br {
            display: none;
        }
    }

    &--small {
        font-size: 1.5rem;
        line-height: 1.5em;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        a {
            opacity: 1;

            &:hover,
            &:focus,
            &:visited:hover,
            &:visited:focus {
                opacity: 1;
                color: $title-color;
            }
        }

        svg {
            height: 1em;
            margin-right: -0.5rem;
        }

        svg + svg {
            margin-right: 0;
        }

        br {
            display: none;
        }
    }
}

.site-body .site-title--small {
    @media screen and (min-width: $big-width) {
        margin-left: 1.25rem !important;
    }
}

.site-desc {
    display: block;
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 1.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    color: darken($bg-color, 70%);
    text-align: left;
}

.to-nav,
.to-nav:visited {
    text-decoration: none;
    font-size: 0.75rem;
    line-height: 1.5em;
    padding: 0.25rem 1.5rem;
    border-radius: 5rem;
    background-color: $code-color;
    color: $t-color;
    display: table;
    margin-top: -0.75rem;
    margin-left: auto;
    margin-right: auto;
    text-transform: uppercase;
    font-weight: 700;

    .site-header & {
        margin-top: 0.25rem;
        margin-bottom: 1.5rem;
    }
}

.site-nav {
    padding-bottom: 0.25rem;
    background-color: $nav-bg-color;
    border-radius: 0.5rem;
    padding-bottom: 0;
}

.category-nav {
    margin: 0 -1.5rem -1.5rem -1.5rem;
}

.site-nav-list,
.categories-nav-list {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.site-nav-item,
.categories-nav-item {
    font-size: 1rem;
    line-height: 1.5em;
    display: block;
    margin-right: 0;

    svg {
        margin-top: 0.35rem;
        margin-bottom: -0.35rem;
    }

    span {
        display: none;
    }

    a,
    a:visited {
        display: block;
        text-decoration: none;
        padding: 1rem 1.5rem;
        font-weight: 700;
        background-color: $nav-bg-color;
        color: $t-color;

        &:hover,
        &:focus {
            background-color: darken($nav-bg-color, 10%) !important;
            color: darken($nav-bg-color, 70%) !important;

            @media screen and (min-width: $big-width) {
                border-top-left-radius: 0.5rem;
                border-bottom-left-radius: 0.5rem;
            }

            svg {
                fill: darken($nav-bg-color, 70%);
            }

            span {
                color: darken($nav-bg-color, 70%);
            }
        }
    }

    span {
        display: block;
        font-weight: 400;
        color: $t-color;
        font-size: 0.75rem;
        line-height: 1.5em;
    }
}

.categories-nav-item {

    &:nth-child(2n) a {
        background-color: transparent;
    }
}

.categories-nav-item a,
.categories-nav-item a:visited {
    color: hsla(250, 100%, 10%, 1);
}

.post-preview-image,
.post-image {
    margin-bottom: 1.5rem;
}

.site-image {
    margin-top: -2.5rem;
    margin-bottom: 3rem;

    &-credit p {
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: lighten($t-color, 30%);
        margin-top: 0.25rem;
        text-align: right;

        a,
        a:visited {
            color: inherit;
        }
    }
}

.post-preview-image a,
.post-preview-image a:focus,
.post-preview-image a:hover,
.post-preview-image a:visited,
.post-preview-image a:visited:focus,
.post-preview-image a:visited:hover {
    background-color: transparent;
    color: $t-color;
}

.post-preview-image img,
.post-image img,
.site-image img {
    max-width: 100%;
    max-width: calc(100% + 3rem);
    width: calc(100% + 3rem);
    margin-left: calc(-1.5rem);
    margin-right: calc(-1.5rem);

    @media screen and (min-width: $big-width) {
        border-radius: 0.5rem;
        max-width: 100%;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

.post-preview-header {
    margin-bottom: 1.5rem;
}

.post-preview-title {
    font-size: 2.25rem;
    line-height: 1.315em;
    margin-top: 0;
    margin-bottom: 0;

    &--link {

        a,
        a:visited {
            text-decoration: underline;
            text-decoration-color: lighten($title-color, 70%);

            &:hover,
            &:focus {
                text-decoration-color: $a-hover-color;
            }
        }

        a[href ^= "http"]::after {
            content: "\000A0↗";
            font-size: 50%;
            line-height: 1rem;
            vertical-align: super;
        }
    }
}

.post-preview-meta {
    background-color: $a-plain-bg-color;
    padding: 0.25rem 0.75rem;
    border-radius: 5rem;
    color: darken($meta-color, 20%);
    font-size: 0.75rem;
    line-height: 1.5em;
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;

    .post & {
        margin-bottom: 1.5rem;
    }

    .site-content > & {
        background-color: darken($bg-color, 5%);
        display: table;
        margin: 1.5rem auto 0 0;
    }

    .post-page .site-content > & {
        margin-bottom: 5rem;
    }
}

.site-content > .post {
    padding-top: 0;
}

.site-content > .post-preview {
    margin-top: 0;
}

.image-right img {
    border-radius: 0.5rem;

    &.right {
        float: right;
        max-width: 25%;
        margin-left: 1.5rem;
        margin-bottom: 1.5rem;
    }
}

.post-content {
    @media screen and (min-width: $big-width) {
        width: 41.8rem;
    }
}

.post:not(.post--link) .post-content > p:first-of-type {
    font-weight: 300;
    padding-bottom: calc(3rem - 1px);
    border-bottom: 1px solid $code-color;
    margin-bottom: 3rem;

    code {
        font-size: 0.9em;
    }

    @media screen and (min-width: $big-width) {
        font-size: 1.25rem;
        line-height: 1.5em;
    }
}

.categories,
.tags,
.social {
    padding: 1.5rem;
    border-radius: 0.5rem;
    margin-top: 3rem;
    background-color: $nav-bg-color;
}

.categories-title,
.tags-title,
.social-title {
    font-size: 1rem;
    line-height: 1.5em;
    border-top: 0.125rem solid darken($bg-color, 20%);
    padding: 1.5rem;
    margin: -1.5rem;
    margin-bottom: 0;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        margin-top: -2px;
        margin-bottom: 2px;
        vertical-align: middle;
    }
}

.tags-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: -3px;
    margin-bottom: 2px;
}

.tags-item {
    display: inline-block;
    margin-top: 0.028rem;
    margin-bottom: 0.005rem;
}

.tags-item a,
.tags-item a:visited {
    font-size: 0.75rem;
    line-height: 1.5em;
    padding: 0.25rem 0.75rem;
    border-radius: 5rem;
    background-color: hsla(47, 100%, 75%, 1);

    span {
        display: inline-block;
        margin-top: -1px;
        margin-bottom: -1px;
        margin-right: -0.75rem;
        padding: 0.15rem 0.75rem;
        padding-left: 0.5rem;
        margin-left: 0.5rem;
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        background-color: hsla(0, 0%, 0%, 0.05);
        color: hsla(47, 100%, 30%, 1);
    }

    &:hover,
    &:focus {
        background-color: hsla(47, 100%, 50%, 1);
        color: hsla(47, 100%, 10%, 1);
    }
}

.tags-footer,
.tags-footer:visited {
    display: block;
    text-decoration: none;
    margin: 1.5rem -1.5rem -1.5rem -1.5rem;
    padding: 0.75rem 1.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0;
    background-color: darken($nav-bg-color, 10%);
    color: darken($nav-bg-color, 65%);
    font-size: 0.75rem;
    line-height: 1.5em;
    text-transform: uppercase;

    &:hover,
    &:focus {
        background-color: darken($nav-bg-color, 20%);
        color: darken($nav-bg-color, 75%);
    }
}

.social {
    padding-bottom: 0;
}

.social .button {
    padding: 0.5rem 1.5rem;
    font-weight: 400;
    text-align: left;
    border-radius: 0;
    margin: 0 -1.5rem;
    background-color: darken($nav-bg-color, 5%);
    color: $title-color;

    &:first-of-type {
        margin-top: -0.75rem;
    }

    &:last-child {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0;
    }

    svg {
        width: 1.5em;
        margin-right: 0.5rem;
        vertical-align: -0.6rem;
        fill: $title-color;
    }

    &:hover,
    &:focus {
        background-color: darken($nav-bg-color, 10%);
        color: darken($nav-bg-color, 70%);

        svg {
            fill: darken($nav-bg-color, 70%);
        }
    }
}

.social .button-grid {
    display: flex;
    margin: 0 -1.5rem;
    margin-top: -0.25rem;

    & > .button {
        flex: 0 1 50%;
        margin: 0;
        text-align: center;

        &:nth-child(2n) {
            border-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:nth-child(2n + 1) {
            border-radius: 0;
            border-bottom-left-radius: 0.5rem;
        }
    }
}

.post a[href ^= "http"]::after {
    content: "\000A0↗";
    font-size: 50%;
    line-height: 1rem;
    vertical-align: super;
}

.categories-nav-item--has-desc a {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.site-title a:hover,
.site-title a:focus {
    background-color: transparent;
}

.media {
    display: flex;
    align-items: stretch;
}

.media-figure {
    margin-right: 1.5rem;
}

.media-body {
    flex: 1;
}

.media--right .media-figure {
    margin-right: 0;
    margin-left: 1.5rem;
    order: 2;
}

.media--right .media-body {
    order: 1;
}

.site-footer {
    background-color: #fff;
}

.site-footer-body {
    @media screen and (min-width: $big-width) {
        max-width: 64rem;
        margin: 0 auto;
        padding: 3rem 0 1.5rem;

        .site-footer-content {
            display: flex;
            justify-content: space-between;
            align-items: stretch;
        }

        .site-footer-aside {
            flex: 0 0 calc(30% - 4.7rem);
            margin-left: 0;
            margin-right: 7.7rem;
        }

        .site-footer-main {
            flex: 1;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 3rem;

            .closure {
                grid-column-start: 1;
                grid-column-end: 4;
            }
        }
    }

    .site-footer-content {
        padding: 3rem 1.5rem 1.5rem 1.5rem;

        @media screen and (min-width: $big-width) {
            padding: 0;
        }
    }

    .site-footer-main {
        margin: 1.5rem 0;

        @media screen and (min-width: $big-width) {
            margin: 0;
        }

        .closure {
            font-size: 0.75rem;
            line-height: 1.5em;
            color: darken($code-color, 70%);
            margin-top: 1.5rem;

            @media screen and (min-width: $big-width) {
                margin-top: 0;
            }
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0 -1rem;

            li {
                display: inline-block;
                width: 49%;

                @media screen and (min-width: $big-width) {
                    display: block;
                    width: 100%;
                }
            }

            svg {
                width: 1.3em;
                vertical-align: -0.6rem;
                margin-right: 0.5rem;
                fill: darken($code-color, 40%);
            }

            a,
            a:visited {
                display: inline-block;

                @media screen and (min-width: $big-width) {
                    display: block;
                }

                padding: 0.25rem 1rem;
                border-radius: 5rem;
                color: darken($code-color, 60%);

                svg {
                    fill: darken($code-color, 60%);
                }

                &:hover,
                &:focus {
                    background-color: hsla($hue, 95%, 92%, 1);
                    color: darken($code-color, 60%);
                    // box-shadow: 0 1px 3px hsla($hue, 90%, 30%, 0.1), 0 0.125rem 0.25rem hsla($hue, 90%, 30%, 0.025);

                    svg {
                        fill: darken($code-color, 60%);
                    }
                }
            }
        }

        .spacer {
            font-size: 0.75rem;
            line-height: 1.5em;
        }
    }
}

.site-footer-aside {

    p {
        font-size: 0.75rem;
        line-height: 1.5em;
        margin: 0;
    }

    .site-title {
        font-weight: 700;
    }

    a,
    a:visited {
        color: darken($code-color, 50%);
        opacity: 1;

        svg {
            fill: darken($code-color, 50%);
        }

        &:hover,
        &:focus {

            svg {
                fill: $title-color;
            }
        }
    }
}

.post,
.site-content {
    padding: 3rem;
    border-radius: 1rem;
    background-color: #fff;
    margin: -3rem;

    @media screen and (max-width: $big-width) {
        margin: -1.5rem;
        padding: 1.5rem;
        border-radius: 0;
    }
}

.post h2 {
    font-size: 1.5rem;
    line-height: 1.5em;
    margin-top: 3rem;
}

.post h3 {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5em;
    margin-top: 2.25rem;
    margin-bottom: 0.75rem;
    letter-spacing: 0.1em;
    color: saturate(adjust-hue(darken($bg-color, 40%), -30), 40%);

    code {
        text-transform: none;
        font-weight: 400;
    }
}

.post dd {
    margin-bottom: 1.35rem;
}

.post dl {
    margin-bottom: 1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    @media screen and (min-width: $big-width) {
        margin-left: 0;
        margin-right: 0;
    }
}

.post .box {
    padding: 0;
    background-color: $nav-bg-color;
    margin: 3rem -1.5rem;

    &:last-child {
        margin-bottom: 0;
    }

    @media screen and (min-width: $big-width) {
        margin: 1.5rem -3rem;
        border-radius: 0;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .box-header {
        padding: 1.5rem 1.5rem 0;
        margin-right: 0;

        svg {
            display: inline-block;
            margin: 0;
            margin-right: 0.5rem;
            width: 1rem;
            vertical-align: -0.55rem;
        }
    }

    .box-body {
        padding: 1.5rem;
        padding-top: 0.75rem;
        padding-bottom: 1.75rem;
    }

    .box-num {
        text-transform: uppercase;
        font-size: 0.75rem;
        line-height: 1.5em;
        display: inline-block;
        padding: 0.15rem 0.5rem;
        border-radius: 0.25rem;
        // background-color: hsla(0, 0%, 0%, 0.05);
        box-shadow: inset 0 0 0 1px hsla(0, 0%, 0%, 0.1);
        vertical-align: 1px;
        margin-right: 0.25rem;
        font-weight: 700;
    }

    & + div + .box {
        margin-top: -1.5rem;
    }

    h3 {
        font-size: 1rem;
        line-height: 1.5em;
        color: inherit;
        font-weight: 600;
        text-transform: none;
        letter-spacing: 0;
        margin-bottom: -0.75rem;
    }
}

.post .box-title {
    font-size: 1rem;
    line-height: 1.5em;
    margin-bottom: 0;
    color: $title-color;
    font-weight: 400;
}

.post .box p {
    margin-bottom: 0;
}

.post .box-content > * {
    margin-top: 0;
    margin-bottom: 0;
}

.post .box-content > * + * {
    margin-top: 1.5rem;
}

.post .box svg {
    margin-top: 0.5rem;
}

.post .box--info {
    $box-color: saturate(adjust-hue(darken($bg-color, 2%), 70), 50%);
    $border-color: darken($box-color, 10%);
    $alt-color: darken($box-color, 60%);

    background-color: $box-color;
    border-color: $border-color;

    .box-title {
        color: $alt-color;
    }

    svg {
        fill: $alt-color;
    }

    a {
        color: $alt-color;
    }

    *:not(pre) > code {
        background-color: darken($box-color, 10%);
    }

    aside code {
        background-color: $code-color;
        font-size-adjust: 0.4;
    }

    figure {
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);
        border-radius: 0.5em;
    }
}

.post .box--correction {
    $box-color: saturate(adjust-hue(darken($bg-color, 4%), 10), 5%);
    $border-color: darken($box-color, 10%);
    $alt-color: darken($box-color, 60%);

    background-color: $box-color;
    border-color: $border-color;

    .box-title {
        color: $alt-color;
    }

    svg {
        fill: $alt-color;
    }

    h3 {
        color: $alt-color;

        + p {
            margin-top: 0.25rem;
        }
    }
}

.post .box--experiment {
    $box-color: saturate(adjust-hue(darken($bg-color, 2%), 10), 50%);
    $border-color: darken($box-color, 10%);
    $alt-color: darken($box-color, 60%);

    background-color: $box-color;
    border-color: $border-color;

    .box-title {
        color: $alt-color;
    }

    svg {
        fill: $alt-color;
    }
}

.post .box--alert {
    $box-color: saturate(adjust-hue(darken($bg-color, 2%), -30), 50%);
    $border-color: darken($box-color, 10%);
    $alt-color: darken($box-color, 60%);

    background-color: $box-color;
    border-color: $border-color;

    .box-title {
        color: $alt-color;
    }

    svg {
        fill: $alt-color;
    }
}

.tag-wrapper {
    border: 0.125rem solid $code-color;
    border-radius: 0.5rem;

    @media screen and (max-width: $big-width) {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
    }
}

.tag-section {
    padding: 3rem;
    border-top: 0.125rem solid $code-color;

    @media screen and (max-width: $big-width) {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    &:first-child {
        border-top: 0;
    }

    &-title {
        font-size: 1.5rem;
        line-height: 1.5em;
    }
}

.compact-preview {
    border-top: 1px solid $code-color;
    padding-top: calc(0.75rem - 1px);

    @media screen and (max-width: $big-width) {
        padding-bottom: 0.75rem;
    }

    &:first-child {
        border-top: 0;
    }

    .post-preview-meta {
        vertical-align: 0.125rem;
    }
}

.compact-preview-title {
    display: inline-block;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5em;

    @media screen and (max-width: $big-width) {
        display: block;
    }
}

.archive {

    .post-preview-meta {
        @media screen and (max-width: $big-width) {
            margin-bottom: 0.75rem;
        }

        @media screen and (min-width: $big-width) {
            float: right;
        }
    }
}

.discuss {
    padding: 1.5rem;
    background-color: lighten($bg-color, 7.5%);
    margin-top: 3rem;
    margin-bottom: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem;

    .button,
    .button:visited {
        display: table;
        margin-bottom: 1.5rem;
        margin-left: auto;
        margin-right: auto;

        @media screen and (min-width: $big-width) {
            display: block;
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 0;
        }

        &::after {
            content: "";
            display: none;
        }

        &:hover,
        &:focus {
            background-color: $a-hover-color;
            color: $bg-color;
        }
    }

    & > * {
        margin-bottom: 0;
    }

    & > * + * {
        margin-top: 1.5rem;
    }

    &-title {
        font-size: 1.5rem;
        line-height: 1.5em;
        margin-bottom: 1.5rem;
    }

    @media screen and (min-width: $big-width) {

        &-content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 3rem;
            grid-row-gap: 3rem;
        }
    }

    &-section {

        & > * {
            margin-bottom: 0;
        }

        & > * + * {
            margin-top: 1.5rem;
        }
    }

    h4 {
        margin-top: 0.25rem;
    }

    @media screen and (min-width: $big-width) {

        .flex {
            display: flex;
            align-items: stretch;

            .discuss-empty {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .flex {

        .discuss-empty {
            border-radius: 0.5rem;
            background-color: darken($discuss-color, 7.5%);
            padding: 1.5rem;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            > div > * {
                margin: 0;
            }

            p {
                color: darken($discuss-color, 70%);
            }
        }
    }

    &-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &-list-item {
        margin-top: 0.4rem;

        svg {
            width: 1.5rem;
            fill: $a-color;
            vertical-align: -0.65rem;
            margin-right: 0.5rem;
        }

        & a,
        & a:visited {
            display: block;
            padding: 0.25rem 1.5rem;
            margin: 0 -1.5rem;
            border-radius: 5rem;
            background-color: transparent;
            text-decoration: none;
            color: $a-color;

            &::after {
                content: "";
                display: none;
            }

            &:hover,
            &:focus {
                background-color: $bg-color;
                color: darken($a-color, 30%);
                box-shadow: 0 1px 3px hsla($hue, 90%, 30%, 0.1), 0 0.125rem 0.25rem hsla($hue, 90%, 30%, 0.025);

                svg {
                    fill: darken($a-color, 30%);
                }
            }
        }
    }

    @media screen and (min-width: $big-width) {
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        margin-top: 0;
        margin-left: -3rem;
        margin-right: -3rem;
        margin-bottom: -3rem;
        padding: 3rem;
    }
}

.pagination {
    display: block;
    margin-top: 3rem;
    border-top: 1px solid $code-color;
    padding: 3rem 0 0 0;

    .button,
    .button:visited {
        font-weight: 400;
        background-color: lighten($a-color, 45%);
        color: $a-color;

        &:hover,
        &:focus {
            background-color: $a-hover-color;
            color: $bg-color;
        }
    }

    a {
        display: inline-block;

        &.left {
            float: left;
        }

        &.right {
            float: right;
        }
    }

    .clearfix {
        clear: both;
    }
}

.other-posts {
    margin-top: 3rem;

    a,
    a:visited {
        display: block;
        background-color: lighten($a-hover-bg-color, 2.5%);
        border-radius: 0.5rem;
        padding: 1.5rem;
        text-decoration: none;
        color: $a-hover-color;
        margin-bottom: 1.5rem;

        &:hover,
        &:focus {
            background-color: $a-hover-color;
            color: $bg-color;
        }

        span {
            display: block;
            font-size: 0.75rem;
            line-height: 1.5em;
            text-transform: uppercase;
            font-weight: 600;
            margin-bottom: 0.75rem;
        }

        h4 {
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: $big-width) {
        display: flex;
        justify-content: space-between;
        align-items: stretch;

        a {
            display: inline-block;
            width: 33.333%;
            margin-bottom: 0;
        }
    }
}

.toc {
    background-color: hsla($hue, 50%, 97%, 1);
    border: 1px solid hsla($hue, 50%, 85%, 1);
    border-radius: 0.5rem;
    padding: 1.5rem;
    columns: 2;
    column-gap: 1.5rem;
    margin-bottom: 1.5rem;

    &-list {
        color: lighten($t-color, 40%);
        list-style: decimal;
        margin: 0;
        margin-left: 1.5rem;
        padding: 0;
    }

    &-list li {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }

    &-sublist {
        list-style: none;
        margin: 0;
        margin-left: 0;
        padding: 0;
        font-size: 0.85rem;
        line-height: 1.5em;
    }

    &-sublist li {
        position: relative;
    }

    &-sublist li::before {
        content: "↳";
        margin-left: 0.25rem;
    }

    a,
    a:visited {
        display: inline-block;
        margin-left: 0.25rem;
        color: $t-color;
        text-decoration: none;
    }

    a:hover,
    a:focus,
    a:visited:hover,
    a:visited:focus {
        background-color: transparent;
        text-decoration: underline lighten($t-color, 40%);
    }
}

.glossary {
    background-color: transparent;
    display: block;
    border-bottom: 1px solid $nav-bg-color;
    border-radius: 0;
    padding: 0;
}

.glossary dt {
    display: block;
    padding: 1.5rem 0;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1.5em;
}

.glossary dd {
    padding: 0.75rem 0;
    padding-top: 0;
    margin-top: -0.75rem;
}

.glossary dt:nth-child(2n+1) {
    border-top: 1px solid $nav-bg-color;
}

.glossary .subdfn {
    margin-left: 1.5rem;
}

.glossary dt.subdfn {
    border-top: 0;
    color: $t-color;
    padding-top: 0;
    font-weight: 400;
    position: relative;
    font-size: 1rem;
    line-height: 1.5em;

    .new {
        font-weight: 600;
        border-bottom: 2px solid darken($code-color, 10%);
    }

    &::before {
        content: "";
        height: 0.25rem;
        width: 0.65rem;
        position: absolute;
        top: 0.6rem;
        left: -1.25rem;
        background-color: darken($code-color, 20%);
    }
}

.glossary dd.subdfn {
    padding-left: 0;
}

.glossary .also {
    font-size: 80%;
    color: $t-color;
    font-weight: 400;
}

.author-block {
    margin-top: 3rem;

    @media screen and (min-width: $big-width) {
        margin-left: -3rem;
        margin-right: -3rem;
        padding: 3rem;
        background-color: lighten($code-color, 4%);
    }
}

.author-image {
    width: 7rem;

    img {
        max-width: 100%;
        border-radius: 0.5rem;
    }
}

.author-name {
    margin-bottom: 0.5rem;
}

.author-position {
    font-weight: 400;
    display: block;
    line-height: 1.5em;
    text-transform: lowercase;
    font-variant: small-caps;
}

.author-desc p:last-child {
    margin-bottom: 0;
}

.mobile-only {
    @media screen and (max-width: $big-width) {
        display: block !important;
    }

    @media screen and (min-width: $big-width) {
        display: none !important;
    }
}

.mobile-only .site-title,
.mobile-only .site-desc {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
}

.mobile-only .site-desc {
    height: 7rem;
}

.visually-hidden:not(:focus):not(:active) {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}
