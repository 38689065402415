
.highlight .hll {
    background-color: hsl(60, 100%, 90%);
}

.highlight .c {
    color: hsl(192, 34%, 53%);
    font-style: italic;
}

.highlight .err {
    // border: 1px solid hsl(0, 100%, 50%);
}

.highlight .k {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .o {
    color: hsl(0, 0%, 40%);
}

.highlight .ch {
    color: hsl(192, 34%, 53%);
    font-style: italic;
}

.highlight .cm {
    color: hsl(192, 34%, 53%);
    font-style: italic;
}

.highlight .cp {
    color: hsl(137, 100%, 22%);
}

.highlight .cpf {
    color: hsl(192, 34%, 53%);
    font-style: italic;
}

.highlight .c1 {
    color: hsl(192, 34%, 53%);
    font-style: italic;
}

.highlight .cs {
    color: hsl(192, 34%, 53%);
    background-color: hsl(0, 100%, 97%);
}

.highlight .gd {
    color: hsl(0, 100%, 31%);
}

.highlight .ge {
    font-style: italic;
}

.highlight .gr {
    color: hsl(0, 100%, 50%);
}

.highlight .gh {
    color: hsl(240, 100%, 25%);
    font-weight: 700;
}

.highlight .gi {
    color: hsl(120, 100%, 31%);
}

.highlight .go {
    color: hsl(0, 0%, 53%);
}

.highlight .gp {
    color: hsl(27, 91%, 41%);
    font-weight: 700;
}

.highlight .gs {
    font-weight: 700;
}

.highlight .gu {
    color: hsl(300, 100%, 25%);
    font-weight: 700;
}

.highlight .gt {
    color: hsl(222, 100%, 43%);
}

.highlight .kc {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .kd {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .kn {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .kp {
    color: hsl(137, 100%, 22%);
}

.highlight .kr {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .kt {
    color: hsl(13, 100%, 28%);
}

.highlight .m {
    color: hsl(150, 43%, 44%);
}

.highlight .s {
    color: hsl(210, 43%, 44%);
}

.highlight .na {
    color: hsl(210, 43%, 44%);
}

.highlight .nb {
    color: hsl(137, 100%, 22%);
}

.highlight .nc {
    color: hsl(198, 86%, 38%);
    font-weight: 700;
}

.highlight .no {
    color: hsl(201, 58%, 61%);
}

.highlight .nd {
    color: hsl(0, 0%, 33%);
    font-weight: 700;
}

.highlight .ni {
    color: hsl(11, 65%, 53%);
    font-weight: 700;
}

.highlight .ne {
    color: hsl(137, 100%, 22%);
}

.highlight .nf {
    color: hsl(223, 91%, 26%);
}

.highlight .nl {
    color: hsl(223, 100%, 22%);
    font-weight: 700;
}

.highlight .nn {
    color: hsl(198, 86%, 38%);
    font-weight: 700;
}

.highlight .nt {
    color: hsl(221, 90%, 24%);
    font-weight: 700;
}

.highlight .nv {
    color: hsl(287, 58%, 61%);
}

.highlight .ow {
    color: hsl(137, 100%, 22%);
    font-weight: 700;
}

.highlight .w {
    color: hsl(0, 0%, 73%);
}

.highlight .mb {
    color: hsl(150, 43%, 44%);
}

.highlight .mf {
    color: hsl(150, 43%, 44%);
}

.highlight .mh {
    color: hsl(150, 43%, 44%);
}

.highlight .mi {
    color: hsl(150, 43%, 44%);
}

.highlight .mo {
    color: hsl(150, 43%, 44%);
}

.highlight .sa {
    color: hsl(210, 43%, 44%);
}

.highlight .sb {
    color: hsl(210, 43%, 44%);
}

.highlight .sc {
    color: hsl(210, 43%, 44%);
}

.highlight .dl {
    color: hsl(210, 43%, 44%);
}

.highlight .sd {
    color: hsl(210, 43%, 44%);
    font-style: italic;
}

.highlight .s2 {
    color: hsl(210, 43%, 44%);
}

.highlight .se {
    color: hsl(210, 43%, 44%);
    font-weight: 700;
}

.highlight .sh {
    color: hsl(210, 43%, 44%);
}

.highlight .si {
    color: hsl(210, 51%, 63%);
    font-style: italic;
}

.highlight .sx {
    color: hsl(27, 91%, 41%);
}

.highlight .sr {
    color: hsl(211, 59%, 34%);
}

.highlight .s1 {
    color: hsl(210, 43%, 44%);
}

.highlight .ss {
    color: hsl(85, 67%, 28%);
}

.highlight .bp {
    color: hsl(137, 100%, 22%);
}

.highlight .fm {
    color: hsl(223, 91%, 26%);
}

.highlight .vc {
    color: hsl(287, 58%, 61%);
}

.highlight .vg {
    color: hsl(287, 58%, 61%);
}

.highlight .vi {
    color: hsl(287, 58%, 61%);
}

.highlight .vm {
    color: hsl(287, 58%, 61%);
}

.highlight .il {
    color: hsl(150, 43%, 44%);
}
